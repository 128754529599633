<template>
	<div class="full-height size-px-14 position-relative">
		<div class="header">
			<div
				class="top top_mafi "
			><!--trans-->
				<div class="util shadow">
					<button
						class="arrow_white"
						@click="goBack"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit ml-15">{{ program.title }} </h2>
				<button
					class="util size-px-16 "
					style="margin-left: auto;"
					@click="postSave"
					:class="disabled ? 'color-gray' : 'color-white'"
				>{{ $language.common.complete }} <!-- 완료 --></button>
			</div>
		</div>
		<div
			class="full-height"
		>
			<div
				class="bg-white full-height pt-50"
			>
				<div
					class="pa-20"
				>
					<div class="cartel_cover radius-20">
						<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 9">
							<v-icon small class="">mdi-camera</v-icon>
							커버 등록
							<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event)" ref="file" file_upload_code_info="CM00700015" />
						</label>
						<div
							v-if="item_cover.src"
							class="cover_background"
						>
							<img
								:src="item_cover.src"
								class="object-cover"
							/>
						</div>

						<div
							v-else
							class="text-center size-px-12 text-shadow flex-column justify-center full-height color-white"
						>커버 이미지를 등록하세요</div>

					</div>
				</div>

				<div
					class="prl-20 "
				>
					<h6 class="size-px-16 font-weight-500 color-333">{{ $language.tv.txt_tv_series_title}} <!-- 제목 --> <span class="color-red">*</span></h6>
					<input
						v-model="post_title"
						class="input-box mt-10"
						:placeholder="$language.tv.txt_tv_series_title"
					/>
				</div>

				<div
					class="prl-20 mt-20"
				>
					<h6 class="size-px-16 font-weight-500 color-333">{{ $language.tv.txt_tv_series_contents}} <!-- 내용 --> <span class="color-red">*</span></h6>
					<textarea
						v-model="post_contents"
						class="width-100 pa-10 radius-10 mt-15 overflow-y-auto"
						rows="5"
						:placeholder="$language.tv.txt_tv_series_contents"
						maxlength="140"
					></textarea>
				</div>
			</div>
		</div>
		<Popup_confirm
			v-if="is_on_confirm"

			@click="toUpload"
			@cancel="toCancel"
		>
			<template
				v-slot:title
			>{{ $language.tv.title_tv_series_upload }} <!-- title_tv_series_upload --> </template>
			<template
				v-slot:main-txt
			>{{ $language.tv.txt_tv_series_upload }} <!-- 추가하신 시리즈에 영상을 업로드 할 준비가 완료 되었습니다. 영상을 업로드 하시겠습니까? --></template>
			<template
				v-slot:name-cancel
			>{{ $language.tv.btn_tv_series_after}} <!-- 나중에 --></template>
			<template
				v-slot:name-confirm
			>{{ $language.tv.btn_tv_series}} <!-- 업로드 --> </template>
		</popup_confirm>
	</div>
</template>

<script>

import InputFileNew from "@/components/InputFileNew";
import Popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'MafiaTvSeries'
	, components: {Popup_confirm, InputFileNew }
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'MafiaTvSeries'
				, title: this.$language.tv.title_tv_series
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, file_max: 1
			, files: []
			, upload_files: []
			, post_file_list: [
				{}
				,{}
			]
			, item_cover: {
				src:''
			}
			, item_board_config: {

			}
			, is_show: false
			, mode: 'empty'
			, post_title: ''
			, post_contents: ''
			, appendix_file_div_code: 'CA01000001'
			, file_upload_code: 'CM00700015'
			, stremg_video_file_list: []
			, item_aws: {

			}
			, item_video: {

			}
			, item: {

			}
			, is_cover: false
			, parents_post_number: '' //카테고리가 시리즈일 때 이 값이 없으면 에피소드로 등록됨
			, post_level: 1 //0: 단편/기타, 1:시리즈, 2:에피소드
			, catgy_code: 'CA04300002' //CA04300001:전체,CA04300002:시리즈/에피소드,CA04300003:단편, 시리즈는 부모게시번호 필요
			, is_on_confirm: false
			, item_upload: {

			}
		}
	}
	, computed: {
		disabled: function(){
			let t = true
			if(this.item_cover.src && this.post_title && this.post_contents){
				t = false
			}
			return t
		}
	}
	, methods: {
		setFile: function (e) {

			let d = e.split('.')
			let t = d[d.length - 1]

			let contents_meta_data = {

				contents_type: t
				, contents_original_resolu_width: 0
				, contents_original_resolu_height: 0
				, contents_original_rolling_direction: 0
				,contents_length_seconds: '0'
			}

			this.upload_files[0] = {
				post_appendix_file_div_code: this.appendix_file_div_code
				, post_appendix_file_indexnumber: 1
				, post_file_url: e
				, contents_meta_data: contents_meta_data
			}
			this.item_cover.src = e
		}
		, toFile: function () {
			this.$bus.$emit('not_pause')
		}
		, goBack: function(){
			this.$emit('goBack')
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board_config = result.data
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, toEdit: function(){
			this.mode = 'edit'
		}
		, postSave: async function(){
			if(!this.post_contents){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.shorts.error_shorts_contents})
				return false
			}
			try {
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_cartel_tv
				if(this.$route.params.p_id){
					url = this.$api_url.api_path.put_cartel_tv
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.p_id
						, parents_post_number: this.parents_post_number
						, post_level: this.post_level
						, post_title: this.post_title
						, post_contents: this.post_contents
						, file_appendix_fg: this.upload_files.length > 0 ? 'Y' : 'N'
						, post_file_list: this.upload_files
						, catgy_code: this.catgy_code
					}
					, type: true
				})

				if (result.success) {
					this.item_upload = result.data
					this.is_on_confirm = true
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFile: async function(file){
			try{
				this.$bus.$emit('on', true)
				let login_info = this.$encodeStorage.getSessionAt()

				let domain = process.env.VUE_APP_DOMAIN
				let domain_m = process.env.VUE_APP_DOMAIN_M
				let domain_stg = process.env.VUE_APP_DOMAIN_STG
				let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
				let domain_dev = process.env.VUE_APP_DOMAIN_DEV
				let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
				let dev = process.env.VUE_APP_DEV
				let server = process.env.VUE_APP_SERVER
				let stg = process.env.VUE_APP_STG
				let local = process.env.VUE_APP_LOCAL
				let location = window.location.href

				let baseUrl = ''

// 운영계 API 호출
				if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
					baseUrl = server

// 검증계 API 호출
				}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
					baseUrl = stg

// 개발계 API 호출
				}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
					baseUrl = dev

// 로컬 API 호출
				}else{
					baseUrl = local
				}

				let data = new FormData();

				data.append('member_number', login_info.member_number)
				data.append('file_upload_code', this.file_upload_code)
				data.append('appendix_file_div_code', this.appendix_file_div_code)
				data.append('appendix_file_name',  file)

				const options = {
					method: 'POST'
					, body: data
					, headers: {
						'Accept': 'application/json',
						'A': this.$encodeStorage.getSessionToken(),
						'V': '1.0.0'
						, 'Content-Security-Policy' : 'upgrade-insecure-requests'
					}
				};

				let url = baseUrl + '/' + this.$api_url.api_path.post_file
				await fetch(url, options).then(response => response.json()).then( (result) => {
					console.log(result.d)
					console.log(result.resultinfo)
					if(result.resultinfo.result_div_cd == 'OK'){

						this.post_file_list[0] = {
							post_appendix_file_div_code: this.appendix_file_div_code
							, post_appendix_file_indexnumber: 1
							, post_file_url: result.d.file_url
						}
					}else{
						throw result.resultinfo.result_msg
					}
				}).catch( (e) => {
					console.log(e)
					throw e
				});

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
				this.is_cover = false
			}
		}
		, postAws: async function(){
			try{
				this.$bus.$emit('on', true)
				let file = this.item_video;
				console.log('file', file)
				let item = this.item_aws
				const result = await this.$Request({
					method: 'put'
					, url: item.stremg_video_file_list[0].stremg_video_file_url
					, header: {
						// 올리고자하는 동영상의 확장자로 설정 - test-video 파일의 경우 mp4
						"Content-Type": "video/" + this.stremg_video_file_list[0].contents_meta_data.contents_type,
						// "SHTP-02-001 숏플 게시물 등록 요청" - stremg_video_upload_header {key:value} 로 리퀘스트 헤더에 추가
						"x-amz-tagging": item.stremg_video_file_list[0].stremg_video_upload_header['X-Amz-Tagging'][0]
						, "amz-sdk-request" : ["attempt=1; max=3"]
					}
					, data: file
					, type: true
					, is_data: true
					, isResult: true
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.shorts.txt_tv_success})
					this.$bus.$emit('goBack')
				}else{
					throw result.message
				}
			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_tv
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.p_id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					this.post_title = this.item.post_title
					this.post_contents = this.item.post_contents
					this.item_cover.src = this.item?.post_file_list[0]?.post_appendix_file_org_url
					if(this.item.post_file_list.length > 0){
						this.upload_files[0] = {
							post_appendix_file_div_code: this.item.post_file_list[0].post_appendix_file_div_code
							, post_appendix_file_indexnumber: this.item.post_file_list[0].post_appendix_file_indexnumber
							, post_appendix_file_change_before_indexnumber: this.item.post_file_list[0].post_appendix_file_indexnumber
							, post_file_url: this.item.post_file_list[0].post_appendix_file_org_url
							, contents_meta_data: this.item.post_file_list[0].contents_meta_data
						}
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onLoadMetadata: function(e){
			console.log('onLoadMetadata', e.target.videoWidth, e.target.videoHeight, e)
			let width = e.target.videoWidth
			let height = e.target.videoHeight
			let duration = e.target.duration
			let name = this.stremg_video_file_list[0].stremg_video_file_name
			let d = name.split('.')
			let t = d[d.length - 1]
			let data = {
				contents_type: t // "콘텐츠타입", ->required   //png, mp4, mp3 등(현재는 미사용)
				, contents_original_resolu_width: width // ": 콘텐츠원본해상도너비, ->required   //동영상 또는 이미지의 너비(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_resolu_height: height // ": 콘텐츠원본해상도높이, ->required   //동영상 또는 이미지의 높이(정수), 회전이 없는 컨텐츠일 경우 0
				, contents_original_rolling_direction: height > width ? 9 : 0 // ": 콘텐츠원본회전방향, ->required   //0(회전이 없는 컨텐츠), 1(회전 없음), 3(180도 회전), 6(시계 방향으로 90도 회전), 8(반시계 방향으로 90도 회전)
				, contents_length_seconds: duration + ''// ": 콘텐츠길이초 ->required
			}
			this.$set(this.stremg_video_file_list[0], 'contents_meta_data', data)
			this.$set(this.post_file_list[1], 'contents_meta_data', data)

			setTimeout( () => {
				this.capture()
				setTimeout( () => {
					this.save()
				}, 100)
			}, 300)

			console.log('onLoadMetadata', this.stremg_video_file_list)
		}
		, pausePlay: function(){
			let d = this.$refs.video
			console.log(d.paused)
			if(d.paused){
				d.play()
				this.$set(this.upload_files[0], 'paused', false)
			}else{
				d.pause()
				this.$set(this.upload_files[0], 'paused', true)
			}
		}
		, toUpload: function(){
			console.log({ idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: this.item_upload.bulletin_number})

			this.$bus.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: this.item_upload.bulletin_number, type: 'episode'}})
		}
		, toCancel: function(){
			this.$emit('goBack')
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getBoardConfig()
		if(this.$route.params.p_id){
			this.getData()
		}
	}

	, watch: {
	}

}
</script>
<style scoped>
textarea{border: 1px solid #dadada;}
textarea::placeholder{color: #bbb;}
</style>